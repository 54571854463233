import { Dispatch, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { UnknownAction } from '@reduxjs/toolkit';

import { getGlobalDateOptions } from '../../../consts';
import {
    selectPlatform,
    setEmissionsMapViewState,
    setGlobalDate,
    setPlatform,
    setSelectedFeatureParams,
    setTargetDate,
    setUrlParamsInitialized
} from '../../../reducers';
import { RootState } from '../../../store';
import {
    isSTACCollectionId,
    isUrlFeatureType,
    Platforms,
    STACCollection,
    UrlFeatureTypes,
    UrlFeatureTypeToLayerId,
    UrlStateItem,
    ViewStatePropsQueryString
} from '../../../types';
import {
    analytics,
    buildSearchObject,
    getDayEnd,
    parseQueryString,
    setInLocalStorage,
    unpackQueryObject
} from '../../../utils';
import {
    QueryStringObject,
    validateUrlCollectionId,
    validateUrlDateString,
    validateUrlFeatureDateString,
    validateUrlFeatureType,
    validateUrlGlobalDateString,
    validateUrlPlatformParam,
    validateUrlString,
    validateUrlViewState
} from '../../../utils/urlValidators';

/**
 * Custom hook to keep the url querystring in sync with specific pieces of app state.
 * Updates the url
 * Accepts a list of object defining which keys & values the hook should add to the querystring
 * and how those key/value pairs should be parsed on page load.
 */
export function useSyncQueryString({
    urlStateItems,
    dispatch,
    urlUpdateFn
}: {
    urlStateItems: UrlStateItem[];
    dispatch: Dispatch<UnknownAction>;
    urlUpdateFn: (q: QueryStringObject) => void;
}) {
    const urlParamsInitialized = useSelector(
        (state: RootState) => state.pages.emissions.selectedFeature.urlParamsInitialized
    );
    const platform = useSelector(selectPlatform);
    /**
     * Handles the initial querystring on page load:
     * 1) extracts state information from the querystring
     * 2) dispatches the associated actions to the store
     */
    useEffect(() => {
        const { search } = window.location;
        const initialQueryStringObject = parseQueryString(search);
        let initialCollectionId: STACCollection | null = null;
        let initialFeatureDate = '';
        let initialFeatureDateNumber: number | null = null;
        let initialFeatureTargetId = '';
        let initialFeatureType: UrlFeatureTypes | null = null;
        let initialItemId = '';
        let initialFeatureLat: number | null = null;
        let initialFeatureLng: number | null = null;
        let newGlobalDate = '';
        let newDateAsNumber: number | null = null;
        let newPlatform: Platforms | null = null;

        for (let i = 0, l = urlStateItems.length; i < l; i++) {
            const item = urlStateItems[i];
            const { queryKey } = item;
            const newValue = unpackQueryObject(initialQueryStringObject, queryKey);

            // if the key isn't in the querystring or has no value, do nothing
            if (
                (!newValue && typeof newValue !== 'number') ||
                (typeof newValue === 'object' && Object.keys(newValue).length === 0)
            ) {
                continue;
            }

            if (typeof newValue === 'string') {
                if (queryKey === 'platform') {
                    if (validateUrlPlatformParam(newValue)) newPlatform = newValue;
                } else if (queryKey.includes('date')) {
                    const isValidDate = validateUrlDateString(newValue);
                    if (isValidDate) {
                        const date = new Date(`${newValue} UTC`);
                        // Set the timestamp to the end of the day to
                        // be in sync with all other end-of-period dates in the app
                        const endOfDay = getDayEnd(date);
                        newDateAsNumber = endOfDay.getTime();

                        if (queryKey === 'date') {
                            newGlobalDate = newValue;
                        } else if (queryKey === 'feature-date') {
                            initialFeatureDate = newValue;
                            initialFeatureDateNumber = newDateAsNumber;
                        }
                    }
                } else if (
                    queryKey === 'collection-id' &&
                    validateUrlCollectionId(newValue) &&
                    isSTACCollectionId(newValue)
                ) {
                    initialCollectionId = newValue;
                } else if (queryKey === 'item-id' && validateUrlString(newValue)) {
                    initialItemId = newValue;
                } else if (
                    queryKey === 'feature-type' &&
                    validateUrlFeatureType(newValue) &&
                    isUrlFeatureType(newValue)
                ) {
                    initialFeatureType = newValue;
                } else if (queryKey === 'feature-target' && validateUrlString(newValue)) {
                    initialFeatureTargetId = newValue;
                } else if (queryKey === 'feature-lat') {
                    initialFeatureLat = Number(newValue);
                } else if (queryKey === 'feature-lng') {
                    initialFeatureLng = Number(newValue);
                }
            } else if (typeof newValue === 'object') {
                if (queryKey === 'view') {
                    typeof newValue === 'object' &&
                        validateUrlViewState(newValue) &&
                        dispatch(setEmissionsMapViewState(newValue as ViewStatePropsQueryString));
                }
            }
        }

        if (newPlatform) {
            dispatch(setPlatform(newPlatform));
        }

        // Validate the date against `newPlatform`, or use the current `platform` in the Redux store as
        // a fallback if not provided
        if (
            newDateAsNumber &&
            validateUrlGlobalDateString(newGlobalDate, getGlobalDateOptions(newPlatform || platform))
        ) {
            dispatch(setGlobalDate(newDateAsNumber));
        }

        // The six parameters are required, so the actions must be dispatched together
        if (
            initialCollectionId &&
            initialItemId &&
            initialFeatureType &&
            initialFeatureDate &&
            validateUrlFeatureDateString(initialFeatureDate, new Date(newGlobalDate).getTime(), platform) &&
            initialFeatureDateNumber && // related to initialFeatureDate, included to keep typescript happy
            initialFeatureTargetId
        ) {
            // update url params
            dispatch(
                setSelectedFeatureParams({
                    collectionId: initialCollectionId,
                    itemId: initialItemId,
                    featureType: initialFeatureType,
                    coordinates:
                        initialFeatureLng && initialFeatureLat
                            ? [initialFeatureLng, initialFeatureLat]
                            : [
                                  Number(initialQueryStringObject['view-longitude']),
                                  Number(initialQueryStringObject['view-latitude'])
                              ],
                    targetDate: initialFeatureDate,
                    targetId: initialFeatureTargetId
                })
            );
            // set target date for the selected feature
            dispatch(
                setTargetDate({
                    date: initialFeatureDateNumber,
                    targetId: initialFeatureTargetId
                })
            );

            const layerId = UrlFeatureTypeToLayerId.get(initialFeatureType as UrlFeatureTypes);
            // send analytics data
            analytics.openMethaneLayerDrawerFromUrl({
                collectionId: initialCollectionId,
                itemId: initialItemId,
                layerId: layerId || ''
            });
            setInLocalStorage('introDismissed', true);
        }
        dispatch(setUrlParamsInitialized());
    }, []);

    // update query string on state changes
    useEffect(() => {
        let newQueryObj = {};
        if (urlParamsInitialized) {
            // each time any dependency changes, get the current state of all dependencies
            // prevents missing some state updates due to throttled router.replace
            for (let i = 0, l = urlStateItems.length; i < l; i++) {
                const item = urlStateItems[i];
                const { queryKey } = item;

                const newSearchParams = buildSearchObject(queryKey, item.value);
                newQueryObj = {
                    ...newQueryObj,
                    ...newSearchParams
                };
            }
            urlUpdateFn(newQueryObj);
        }
    }, urlStateItems);
}

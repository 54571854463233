import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { log } from '@methanesat/log';
import {
    basinHighlightColor,
    basinLineColor,
    defaultGeoJsonLayerConfig,
    isLayerIdAllowingMultipleSelections,
    MultipleSelectionsAllowedLayerIds,
    pipelineColor,
    pipelineHighlightColor,
    pointInfrastructureColor,
    pointInfrastructureHighlightColor,
    pointInfrastructureLineColor
} from '../../../../../consts';
import {
    Category,
    MapStateLayerNames,
    NothingHighlighted,
    OGILayerConfig,
    OGILayerIdOrder,
    OGILayerIds,
    OGILayerState,
    OGILayerUpdate,
    Operator
} from '../../../../../types';
import {
    addOneMapFeatureHighlight,
    addMultipleMapFeatureHighlights,
    removeAllMapFeatureHighlights,
    resetOgiNearPlumes,
    setOgiNearPlumes
} from '../../emissionsActions';
import { getRootId, updateLayerHighlight, removeLayerHighlights } from '../../../../../utils';

/**
 * This slice contains the configuration for the map's OGI layers, which include point infrastructure,
 * pipelines, basins, and tile infrastructure, and reducers to update the configs and highlights of the layers.
 */

const initialOGILayerState: OGILayerConfig = {
    layerState: {
        [OGILayerIds.pointInfrastructure]: {
            ...defaultGeoJsonLayerConfig,
            enabled: true,
            filled: true,
            getFillColor: pointInfrastructureColor,
            getPointRadius: 200,
            opacity: 1,
            pickable: true,
            pointRadiusUnits: 'pixels',
            stroked: true,
            lineWidthUnits: 'pixels',
            getLineWidth: 100,
            getLineColor: pointInfrastructureLineColor,
            lineWidthMinPixels: 0.5,
            highlightColor: pointInfrastructureHighlightColor
        },
        [OGILayerIds.pipelines]: {
            ...defaultGeoJsonLayerConfig,
            enabled: true,
            getLineColor: pipelineColor,
            getLineWidth: 100,
            lineWidthMaxPixels: 15,
            lineWidthMinPixels: 2,
            lineWidthUnits: 'meters',
            opacity: 0.5,
            pickable: true,
            stroked: false,
            highlightColor: pipelineHighlightColor
        },
        [OGILayerIds.basins]: {
            ...defaultGeoJsonLayerConfig,
            enabled: true,
            filled: false,
            getLineColor: basinLineColor,
            getLineWidth: 1000,
            lineWidthMaxPixels: 5,
            lineWidthMinPixels: 5,
            lineWidthUnits: 'meters',
            opacity: 1,
            pickable: true,
            stroked: true,
            highlightColor: basinHighlightColor
        },
        [OGILayerIds.tileInfrastructure]: {
            enabled: true,
            highlightedFeatureId: NothingHighlighted.highlightedFeatureId,
            opacity: 1,
            highlightedObject: null
        }
    },
    filters: {
        categories: OGILayerIdOrder.map((id) => ({ id })),
        operators: [],
        ogimFeatures: []
    }
};

/**
 * Helper function to determine if a layer ID belongs to the OGI layer state.
 */
export const isOGILayerStateKey = (key: string): key is keyof OGILayerState => {
    return Object.keys(initialOGILayerState.layerState).includes(key);
};

export const OGILayerSlice = createSlice({
    name: 'ogiLayers',
    initialState: initialOGILayerState,
    reducers: {
        setOGILayerConfig: <LayerId extends keyof OGILayerState>(
            state: OGILayerConfig,
            { payload }: { payload: OGILayerUpdate<LayerId> }
        ) => {
            const { layerId, layerUpdates } = payload;
            if (state.layerState[layerId]) {
                state.layerState[layerId] = {
                    ...state.layerState[layerId],
                    ...layerUpdates
                };
            }
        },
        /** Filters the operators for the map in general (filters which don't belong to any one layer). */
        setOperatorFilter: (state, { payload }: PayloadAction<Operator[]>) => {
            state.filters = {
                ...state.filters,
                operators: payload
            };
        },
        /** Highlights the OGI by cateogry */
        setCategoryFilter: (state, { payload }: PayloadAction<Category[]>) => {
            state.filters = {
                ...state.filters,
                categories: payload
            };
        }
    },
    extraReducers: (builder) => {
        builder
            /** highlight related reducers */
            .addCase(addOneMapFeatureHighlight, (state, action) => {
                const { layerId } = action.payload;
                // Check if the layerId belongs to OGI layers - if not clear OGI layer highlights
                if (!isOGILayerStateKey(getRootId(layerId))) {
                    removeLayerHighlights<OGILayerState>(state.layerState);
                    return;
                }
                updateLayerHighlight<OGILayerState>({ ...action.payload, state: state.layerState });
            })
            .addCase(addMultipleMapFeatureHighlights, (state, action) => {
                const { features, layerId } = action.payload;
                const layerKeys = Object.keys(state) as MapStateLayerNames[];
                const isAllowed = isLayerIdAllowingMultipleSelections(layerId);
                if (!isAllowed) {
                    log.error(
                        `Layer ${layerId} attempted to highlight multiple features but it is not allowed. Configure this using const LAYERS_ALLOWING_MULTIPLE_FEATURE_SELECTION.`
                    );
                    return;
                }

                for (let i = 0, l = layerKeys.length; i < l; i++) {
                    const key = layerKeys[i];
                    /**
                     * if/when we want to update multiple features in non-tile layers,
                     * we will need to update highlightedObjectIndex for those layers
                     */
                    const update = {
                        highlightedFeatureId:
                            key === layerId ? features.map((f) => f.objectId) : NothingHighlighted.highlightedFeatureId
                    };

                    /**
                     * to let typescript know we are updating a map layer's state with the same kind of state
                     */

                    state.layerState[key as MultipleSelectionsAllowedLayerIds] = {
                        ...state.layerState[key as MultipleSelectionsAllowedLayerIds],
                        ...update
                    };
                }
            })
            .addCase(removeAllMapFeatureHighlights, (state) => {
                removeLayerHighlights<OGILayerState>(state.layerState);
            })
            .addCase(resetOgiNearPlumes, (state) => {
                state.filters.ogimFeatures = [];
            })
            .addCase(setOgiNearPlumes, (state, action) => {
                state.filters.ogimFeatures = action.payload.ogimFeatures;
            });
    }
});

export const { setOGILayerConfig, setOperatorFilter, setCategoryFilter } = OGILayerSlice.actions;
export default OGILayerSlice.reducer;

import { MethaneLayerIds, StacFeature } from '../../../types';
import { zoomLevelToFeatureTypeMap } from '../../../consts';

/**
 * Function to determine what latitude and longitude the map
 * should snap to given an item's bounding box. Returns the
 * center of the bounding box.
 */
export const calculateBboxCenter = (item: StacFeature) => {
    const bbox = item.bbox;

    if (!bbox) {
        throw new Error('The bounding box must be defined and contain at least 4 elements');
    }

    let minLon: number, minLat: number, maxLon: number, maxLat: number;

    if (bbox.length == 4) {
        [minLon, minLat, maxLon, maxLat] = bbox;
    } else {
        [minLon, minLat, , maxLon, maxLat] = bbox;
    }

    return { centerLongitude: (minLon + maxLon) / 2, centerLatitude: (minLat + maxLat) / 2 };
};

/**
 * Function to determine what zoom level the map should
 * snap to given a feature type. Features include the
 * following: target, point source, flux/concentration point.
 */
export const calculateMapZoomLevelForFeature = (featureId: MethaneLayerIds) => {
    return zoomLevelToFeatureTypeMap[featureId];
};

import { useMemo } from 'react';
import { OGI_NEAR_PLUME_RADIUS_M } from '../../consts';
import { INFRASTRUCTURE_API_BASE_URL } from '../../environmentVariables';
import {
    MSatPickInfo,
    OGILayerIds,
    OGITileFeatureProperties,
    PipelineFeatureProperties,
    PointOGIMGeoJSONProperties
} from '../../types';
import { useDataAPI } from './data';
import { OGINearPoint } from '../../types';

export const useOGIAPI = function <Data extends OGITileFeatureProperties>(
    info: MSatPickInfo<GeoJSON.Geometry, OGITileFeatureProperties | Data>
): { data: Data; loadingState?: boolean } | null {
    const objectId = info?.object.id;
    const layerId = info?.layer.id;
    const shouldFetch = layerId.includes(OGILayerIds.tileInfrastructure);

    const tileInfrastructureUrl = useMemo(() => {
        // check for valid objectId
        if (typeof objectId !== 'number' || Number.isNaN(Number(objectId))) return null;

        return `${INFRASTRUCTURE_API_BASE_URL}/rpc/feature_meta_data`;
    }, [objectId]);

    const fetchedMetadata = useDataAPI<Data[]>(
        (shouldFetch && tileInfrastructureUrl) || null,
        getOGIMetadataFetchOptions([Number(objectId)])
    );
    const mergedMetadata = fetchedMetadata && {
        ...fetchedMetadata[0],
        ...info.object.properties
    };

    let loadingState;

    if (typeof fetchedMetadata === 'undefined' && typeof tileInfrastructureUrl === 'string') {
        loadingState = true;
    }

    const dataAndLoadingState = { loadingState: loadingState, data: mergedMetadata };

    /**
     * If the clicked layer isn't the OGI tile layer, the data is static
     * and therefore already includes all the metadata available. Run-time
     * typing isn't possible, so ignoring the type error on the next line
     * is necessary.
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return shouldFetch ? dataAndLoadingState || null : { ...dataAndLoadingState, data: info.object.properties };
};

export const useGetOgiNearPlumes = ({ longitude, latitude }: { longitude: number; latitude: number }) => {
    // Fetch the OGIM data near plumes
    return useDataAPI<OGINearPoint[] | []>(
        `${INFRASTRUCTURE_API_BASE_URL}/rpc/features_within_radius?lng_center=${longitude}&lat_center=${latitude}&radius_m=${OGI_NEAR_PLUME_RADIUS_M}`
    );
};

export const getOGIMetadataFetchOptions = (featureIds: (number | string)[]) => ({
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Prefer: 'params=single-object' },
    body: JSON.stringify({ ogim_id: [...featureIds] })
});

export const useGetOgiMetadata = ({ ogimIds }: { ogimIds: (number | string)[] }) => {
    return useDataAPI<PipelineFeatureProperties[] | PointOGIMGeoJSONProperties[]>(
        ogimIds && ogimIds.length > 0 ? `${INFRASTRUCTURE_API_BASE_URL}/rpc/feature_meta_data` : null,
        getOGIMetadataFetchOptions(ogimIds || [])
    );
};

import { envToBoolean } from './utils';

export const SHOW_DESKTOP_HAMBURGER_MENU = envToBoolean(process.env.NEXT_PUBLIC_SHOW_DESKTOP_HAMBURGER_MENU);

export const USE_SPEED_DIAL = envToBoolean(process.env.NEXT_PUBLIC_USE_SPEED_DIAL);

export const SHOW_SHAREABLE_FEATURE_LINK = envToBoolean(process.env.NEXT_PUBLIC_SHOW_SHAREABLE_FEATURE_LINK);

export const SHOW_LOGOUT_BUTTON = envToBoolean(process.env.NEXT_PUBLIC_SHOW_LOGOUT_BUTTON);

export const SHOW_INTRO_MODAL = envToBoolean(process.env.NEXT_PUBLIC_SHOW_INTRO_MODAL);

export const SHOW_DATA_COLLECTION_HEADLINE = envToBoolean(process.env.NEXT_PUBLIC_SHOW_DATA_COLLECTION_HEADLINE);

import { log } from '@methanesat/log';
import { QueryStringObject } from './urlValidators';
import { isRecord } from '../types';

/**
 * Function to parse the querystring of a url.
 * Returns an object representing the querystring: { somekey: 'some-value' }.
 * If a key appears multiple times in the querystring, values are concatenated & separated by commas,
 * ex: { key: 'value-1,value-2'}.
 * Parsing comma-separated values must be done separately.
 *
 * TODO: Changing this function results in a server-side change & full refresh (at least sometimes).
 * Investigate possible unintended side-effects.
 */
export function parseQueryString(searchString: string): QueryStringObject {
    let cleanedSearchString = searchString;
    if (searchString[0] === '?') cleanedSearchString = cleanedSearchString.substring(1);

    const pairStrings = cleanedSearchString.split('&');
    const keyValPairs = pairStrings.map((pair) => pair.split('='));

    const result: QueryStringObject = {};

    for (let i = 0, l = keyValPairs.length; i < l; i++) {
        const [key, value] = keyValPairs[i];

        if (!key || !value) {
            continue;
        } else if (key in result) {
            result[key] = `${result[key]},${value}`;
        } else {
            result[key] = value;
        }
    }
    return result;
}

/**
 * Data massaging for URL search param values for some keys before they're added to the URL.
 * TODO: this is a fairly brittle function.
 * https://methanesat.atlassian.net/browse/DP-4459
 */
export function modifyURLSearchValue(value: string, key: string) {
    switch (key) {
        case 'latitude':
        case 'longitude':
        case 'feature-lng':
        case 'feature-lat':
            /**
             * 5 decimal points gives us ~1m resolution and also keeps the URL only as long as needed.  Ideally keep the
             * URL as short as possible to keep it unobtrusive in contexts where it would be shared, e.g. by email.
             * See https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
             */
            return parseFloat(value).toFixed(5);
        default:
            return value;
    }
}

/**
 * Builds a URL query object from a value.
 * Currently supports numbers, strings, booleans, arrays & objects.
 */
export function buildSearchObject(queryKey: string, value: unknown[] | unknown): QueryStringObject {
    if (!value && typeof value !== 'boolean' && typeof value !== 'number') return {};
    if (Array.isArray(value)) {
        return { [queryKey]: value.map((v) => String(v)).join(',') };
    }

    // Using IsRecord seems silly, but typescript complains otherwise.
    // This prevents other type-related contortions.
    if (isRecord(value)) {
        return Object.keys(value).reduce((newParams, key) => {
            return {
                ...newParams,
                [`${queryKey}-${key}`]: modifyURLSearchValue(`${value[key]}`, key)
            };
        }, {});
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
        if (queryKey === 'feature-lat' || queryKey === 'feature-lng') {
            return { [queryKey]: modifyURLSearchValue(`${value}`, queryKey) };
        }
        return { [queryKey]: String(value) };
    }

    if (typeof value === 'string') return { [queryKey]: value };

    log.error(`Cannot convert type ${typeof value} to QueryStringObject. ${value}`);

    return {};
}

/** Function to extract a value from a queryObject by key */
export function unpackQueryObject(queryObject: QueryStringObject, searchKey: string) {
    if (searchKey in queryObject) {
        return queryObject[searchKey];
    }
    return unpackNestedObject(queryObject, searchKey);
}

/**
 * Function to check a query object for prefixed keys and extract them into a new object.
 * Ex:
 * Calling a query object with the key 'view':
 * unpackNestedObject({ view-latitude: 31, view-longitude: -100, mapStyle: MapStyles.dark }, 'view')
 * returns: { latitude: 31, longitude: -100 }
 */
export function unpackNestedObject(queryObject: QueryStringObject, searchKey: string) {
    const parsedObject: QueryStringObject = {};
    const relevantKeys = Object.keys(queryObject).filter((key) => key.includes(`${searchKey}-`));

    for (let i = 0, l = relevantKeys.length; i < l; i++) {
        const relevantKey = relevantKeys[i];
        const strippedKey = relevantKey.replace(`${searchKey}-`, '');
        parsedObject[strippedKey] = queryObject[relevantKey];
    }
    return parsedObject;
}

import { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar';
import type { Theme, PaletteColor } from '@mui/material/styles';

import { PaletteColorOptions } from '@mui/material/styles';
export type { PaletteColorOptions, SxProps, Theme, ThemeOptions } from '@mui/material/styles';
export type { Breakpoint } from '@mui/material/styles';
export type { TypographyProps } from '@mui/material/Typography';

export const AlertSeverityArray = ['error', 'warning', 'success', 'info'] as const;
export enum AlertSeverity {
    error = 'error',
    warning = 'warning',
    success = 'success',
    info = 'info'
}

export interface AvatarProps extends MuiAvatarProps {
    /**
     * Takes the first character of the string and capitalizes it.
     */
    name?: string;
}

export type CalendarData = {
    id: string;
    date: Date;
    color: string;
    coordinates: number[][];
};

export type DateOption = { value: number; label?: string; disabled?: boolean };

export enum CommonPaletteColorKey {
    white = 'white',
    black = 'black'
}

export type CommonPaletteColorKeys = keyof Theme['palette']['common'];

/** CSS color keyword, e.g. `blue`. */
export type CSSColorKeyword = string;

/** CSS color via #-hexadecimal, rgb() or rgba(). */
export type CSSColorRGB = string;

/** CSS color hsl() or hsla() function. */
export type CSSColorHSL = string;

/** CSS var() notation, e.g. var(--main-color); */
export type CSSVariable = string;

/**
 * CSS color value.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
 */
export type CSSColor = CSSColorKeyword | CSSColorRGB | CSSColorHSL | CSSVariable;

const mainPaletteKeys = ['primary', 'secondary', 'error', 'warning', 'info', 'success'] as const;
export type MainPaletteKeys = (typeof mainPaletteKeys)[number];

export function isMainPaletteKey(value: string): value is MainPaletteKeys {
    return mainPaletteKeys.indexOf(value as MainPaletteKeys) > -1;
}
// Our default will automatically close after a set amount of time. This means that
// an onClose function is now required to be passed in. If we don't want to pass
// in an onClose function, we need to overwrite the value of autoHideDuration so
// the snackbar doesn't automatically close.
export type SnackbarProps =
    | (MuiSnackbarProps & Required<Pick<MuiSnackbarProps, 'onClose'>>)
    | (MuiSnackbarProps & { autoHideDuration?: null });

export enum ThemeMode {
    light = 'light',
    dark = 'dark'
}

// allows additional colors to the palette
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        deepPurple: Palette['primary'];
        purple: Palette['primary'];
        yellow: Palette['primary'];
        lightGray: Palette['primary'];
        progressBar: Palette['primary'];
    }
    interface PaletteOptions {
        deepPurple?: PaletteOptions['primary'];
        purple?: PaletteOptions['primary'];
        yellow?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles/createMixins' {
    interface Mixins {
        chip?: CSSProperties;
        mapModalShadow: string;
        speedDial: CSSProperties;
        srOnly: CSSProperties;
        mapButton: CSSProperties;
        modalBox: CSSProperties;
        gradientButton: CSSProperties;
    }
    interface MixinsOptions {
        chip?: CSSProperties;
        mapModalShadow?: string;
        speedDial?: CSSProperties;
        srOnly?: CSSProperties;
        mapButton: CSSProperties;
        modalBox?: CSSProperties;
        gradientButton?: CSSProperties;
    }
}

// allows adding a `button` section to the theme
declare module '@mui/material/styles/createTheme' {
    interface Shape {
        modalArrowSize: number;
        borderRadius: number;
    }
    interface ShapeOptions {
        modalArrowSize?: number;
        borderRadius?: number;
    }

    interface Theme {
        button: {
            [key: string]: PaletteColor;
        };
        shape: Shape;
    }
    interface ThemeOptions {
        button?: {
            [key: string]: PaletteColorOptions;
        };
        shape?: ShapeOptions;
    }
}

export enum LocaleDateVariant {
    /** MMM DD YYYY, e.g. Jul 1 2021 */
    day,
    /** MMM YYYY, e.g. Jul 2021 */
    shortMonthYear,
    /** Q YYYY, e.g. Q2 2024 */
    quarterly
}

export interface LocaleDateProps {
    date: number;
    /**
     * Locale. Prefer to set to undefined in order to default to user's default locale.  This param is mostly used for
     * the convenience of tests.
     * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument
     */
    locale?: Intl.LocalesArgument | undefined;
    variant?: LocaleDateVariant;
}

export interface FormatLocaleDateProps extends LocaleDateProps {}

/**
 * Type guard using a predicate to ensure item is a Record.
 */
export function isRecord(item: unknown): item is Record<string, unknown> {
    if (typeof item !== 'object') return false;

    if (Array.isArray(item)) return false;

    // handle null values
    if (!item) return false;

    // check for date, regexp
    if (item instanceof Date || item instanceof RegExp || item instanceof Map || item instanceof Set) return false;

    return typeof item === 'object';
}

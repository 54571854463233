import { EMISSIONS_PRODUCT_UNITS } from '../../consts';
import { AreaEmissionsProduct, MethaneEmissionsProducts } from '../../types';
import { useTranslate } from '../internationalization';

/**
 * Returns the translated units label for L4 area emissions.
 *
 * To change the units for a given product, update the corresponding property
 * of `EMISSIONS_PRODUCT_UNITS` in `consts/data.ts`
 */
export const useL4UnitsLabel = () => {
    const t = useTranslate();
    return t(`emissionsMapPage.units.${EMISSIONS_PRODUCT_UNITS[MethaneEmissionsProducts.l4]}`);
};

/**
 * Returns the translated units label for L3 area emissions.
 *
 * To change the units for a given product, update the corresponding property
 * of `EMISSIONS_PRODUCT_UNITS` in `consts/data.ts`
 */
export const useL3UnitsLabel = () => {
    const t = useTranslate();
    return t(`emissionsMapPage.units.${EMISSIONS_PRODUCT_UNITS[MethaneEmissionsProducts.l3]}`);
};

/**
 * Returns the translated units label for area emissions of the given product.
 *
 * To change the units for a given product, update the corresponding property
 * of `EMISSIONS_PRODUCT_UNITS` in `consts/data.ts`
 */
export const useAreaEmissionsUnitsLabel = (product: AreaEmissionsProduct) => {
    const t = useTranslate();
    return t(`emissionsMapPage.units.${EMISSIONS_PRODUCT_UNITS[product]}`);
};

/**
 * Returns the translated units label for plume emissions
 *
 * To change the units for a given product, update the corresponding property
 * of `EMISSIONS_PRODUCT_UNITS` in `consts/data.ts`
 */
export const usePlumeEmissionsUnitsLabel = () => {
    const t = useTranslate();
    return t(`emissionsMapPage.units.${EMISSIONS_PRODUCT_UNITS[MethaneEmissionsProducts.plume]}`);
};

/** Returns the translated units label for areas */
export const useAreaUnitsLabel = () => {
    const t = useTranslate();
    return t(`emissionsMapPage.units.km2`);
};

export const useLengthUnitsLabel = () => {
    const t = useTranslate();
    return t(`emissionsMapPage.units.km`);
};

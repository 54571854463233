import {
    sunset5WithoutVibrantYellow,
    sunset11,
    grey11,
    Color,
    colorArrayToRgb,
    getLinearColorStops,
    setDeckGLAlpha,
    ColorRange
} from '@methanesat/colors';
import { MethaneLayerIds, Platforms } from '../types';
import { ScaleLinear, scaleLinear, ScaleThreshold } from 'd3-scale';
import { ColorStopLabel } from '../components/EmissionsMapLegend/MiniLegend/MiniLegendUtils';
import { PLATFORM_RASTER_SIZE } from './data';

export const AREA_EMISSION_PALETTE = sunset5WithoutVibrantYellow;

/**
 * Ranges for total emissions and dispersed sources, for both MAIR and MSAT.
 *
 * Area emissions - Max value correlates to *largest physically possible* kg/hr/km2 value.
 * This enables us to tell the story "is this data point dirty compared to other data points?"
 */

export const EMISSIONS_RANGES = {
    [Platforms.MAIR]: {
        [MethaneLayerIds.targets]: {
            MIN: 10_000,
            MAX: 140_000
        },
        [MethaneLayerIds.areaEmissionRaster]: {
            MIN: 0,
            MAX: 23
        }
    },
    [Platforms.MSAT]: {
        [MethaneLayerIds.targets]: {
            MIN: 25_000,
            MAX: 400_000 /** Via Kira on Slack Oct 16: "Ritesh said they expect the top end to be between 300K and 500K kg/hour" */
        },
        [MethaneLayerIds.areaEmissionRaster]: {
            MIN: 0,
            MAX: 16
        }
    }
};

export const getTargetColorRange = (platform: Platforms) => {
    return EMISSIONS_RANGES[platform][MethaneLayerIds.targets];
};

export const getRasterColorRange = (platform: Platforms) => {
    const { MIN: rawMin, MAX: rawMax } = EMISSIONS_RANGES[platform][MethaneLayerIds.areaEmissionRaster];

    // TODO improvement on hard-coded hack to support ~25km2 data for MSAT.
    // In actuality it varies by latitude. We will need to encode in the STAC item
    // https://methanesat.atlassian.net/browse/DP-4540
    return {
        MIN: rawMin,
        MAX: rawMax * Math.pow(PLATFORM_RASTER_SIZE[platform], 2)
    };
};

/**
 *
 * TARGETS
 *
 * Target ranges.  Max value correlates to the basin with the highest *average* kg/hr/km2.
 * This enables us to tell the story "is this target dirty compared to other targets?"
 * Note: right now dirtiest is defined as highest mean kg/kg/hr (mean = total emissions / ground area).  In the future
 * we may be able to define dirtiest as highest methane intensity (intensity = total emissions / total production in
 * barrels of oil equivalent).
 */

/** Color range applied to target gauge and mapped targets */
export const TARGET_PALETTE = sunset11;
// The summarized palette is used for icons to show a subset of the target palette
export const SUMMARIZED_TARGET_PALETTE = [
    TARGET_PALETTE[0],
    TARGET_PALETTE[1],
    TARGET_PALETTE[3],
    TARGET_PALETTE[6],
    TARGET_PALETTE[10]
];
export const TARGET_PALETTE_MISSING_DATA = grey11;

export const TARGET_GREY_DATA = '#808080';

export const targetColorStopCount = 5;

/**
 * We want to use a logarithmic scale so that there are decreased distances
 * between colors changes at lower flux values, and increased distances at
 * higher values. This allows users to visually disambiguate between low level
 * emission targets (ranges in the tens) from high level emitters (ranges in
 * the hundreds) simultaneously.
 * For this reason the distinguishing colors are placed a long a logarithmic
 * scale. Such that exp(N/x) = 100. Where N is the number colors in the palette
 * minus 1.
 * x solves N/ln(100). Then for n values 0 - N, each step solves exp(n/x).
 * Therefore, for 5 steps we are solving exp(n/0.869)
 */

const getFiveStopLinearScale = (platform: Platforms) => {
    const { MAX } = getRasterColorRange(platform);
    return [100 / MAX, 25, 50, 75, 100]; // these are percentages 1 - 100%
};
export const getEmissionsColorStops = (platform: Platforms) => {
    const fiveStopLinearScale = getFiveStopLinearScale(platform);
    const { MAX } = getRasterColorRange(platform);
    const colorStops = fiveStopLinearScale.map((n: number) => (n * MAX) / 100).reverse();
    return colorStops;
};

export const FIVE_STOP_LOG_SCALE = [1, 3, 10, 32, 100]; // these are percentages 1 - 100%
export const COLOR_STOPS = getEmissionsColorStops(Platforms.MAIR);

// Concentrations have ranges around 1800 and 2400 for preliminary data
export const CONCENTRATION_RANGE = [1880, 2280];
export const CONCENTRATION_COLOR_STOPS_LOG = FIVE_STOP_LOG_SCALE.map((n: number) => {
    const step = n === 1 ? 0 : n; // Given the min is not 0 by default, start scale at min
    const low = CONCENTRATION_RANGE[0];
    const high = CONCENTRATION_RANGE[1];
    const dif = high - low;
    // For more information on data normalizations see: https://www.indeed.com/career-advice/career-development/normalization-formula
    // Specifically: "Normalization formula for custom ranges"
    return low + step * (dif / 100); // rescale on range
}).reverse();

export enum RASTER_COLORMAP_NAMES {
    msatFlirLinear = 'msat_flir_linear',
    msatFlirLog = 'msat_flir_log',
    msatRainbowLog = 'msat_rainbow_log'
}

export const TITILER_COLORMAP = RASTER_COLORMAP_NAMES.msatFlirLinear;

export const DEFAULT_TARGET_FILL_COLOR: Color = [0, 0, 0, 0];

const createTargetDomain = (platform: Platforms) => {
    const { MIN, MAX } = getTargetColorRange(platform);
    return getLinearColorStops(11, MIN, MAX);
};
/** domains (arrays of color stop thresholds) to pass to d3 scale for both MAIR and MSAT targets*/
export const TARGET_DOMAINS = {
    [Platforms.MAIR]: createTargetDomain(Platforms.MAIR),
    [Platforms.MSAT]: createTargetDomain(Platforms.MSAT)
};

export const makeLinearColorScale = (domain: number[], colors: ColorRange): ScaleLinear<Color, Color> => {
    return scaleLinear<Color, Color>().domain(domain).range(colors);
};

export const getColorFromScale = (value: number, scale: ScaleThreshold<number, Color> | ScaleLinear<Color, Color>) => {
    return scale(value);
};

/**
 * Returns a color value, given a TargetFeature with the totalkKgHr property
 */
export function getTargetColor(totalKgHr: number | undefined, platform: Platforms = Platforms.MAIR) {
    const targetColorScale = makeLinearColorScale(TARGET_DOMAINS[platform], TARGET_PALETTE);
    const color = getColorFromScale(totalKgHr || 0, targetColorScale);
    return color;
}

export const getFilledTargetColor = (totalMethane?: number, platform?: Platforms) => {
    return setDeckGLAlpha(getTargetColor(totalMethane, platform), 255 * 0.85);
};

/**
 * Returns the proportion of an emission value relative to the max for a given platform (0 to 1).
 */
const mapEmissionToProportionOfMax = (value: number, platform: Platforms) => {
    const { MIN, MAX } = getTargetColorRange(platform);
    return Math.min((value - MIN) / (MAX - MIN), 1);
};

/**
 * Maps each color on the target palette to a percentage, allowing for interpolation across
 * both linearly and non-linearly spaced labels This enables us to stretch/compress the color gradient used
 * on a scale in certain places if necessary.
 *
 * @param labels: Array of `ColorStopLabel`.
 * @example
 * interpolateColorScalePercentages([{ label: '10,000', percent: 0 }, { label: '30,000', percent: 50 }, { label: '140,000', percent: 100 }])
 *  -> [
 *       { percent: 0, color: 'rgb(9, 1, 121)' },
 *       { percent: 50, color: 'rgb(160, 6, 143)' },
 *       { percent: 55.56, color: 'rgb(182, 43, 121)' },
 *       ...
 *     ]
 */
export const interpolateColorScalePercentages = (labels: ColorStopLabel[], platform: Platforms) => {
    const labelProportions = labels.map((l) => {
        const val = parseFloat(l.label.replace(/,/g, ''));
        return mapEmissionToProportionOfMax(val, platform);
    });

    const scale = scaleLinear()
        .domain(labelProportions)
        .range(labels.map((l) => l.percent));

    const percentageStops = TARGET_PALETTE.map((_, i) => scale(i / (TARGET_PALETTE.length - 1)));

    const colorPercentages = TARGET_PALETTE.map((c, index) => ({
        percent: percentageStops[index],
        color: colorArrayToRgb(c)
    }));

    return colorPercentages;
};

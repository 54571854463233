import { DateOption } from '@methanesat/ui-components';
import { ENVIRONMENT } from '../environmentVariables';
import { Platforms } from '../types';
import { getMonthEnd } from '../utils/time';

const sortDateDescending = (date1: Date, date2: Date) => date2.getTime() - date1.getTime();

// Monthly date range
// (months are 0-indexed)
//
// TODO: get dates from STAC, rather than this long list
// https://methanesat.atlassian.net/browse/DP-4286
const monthlyGlobalDateOptions =
    ENVIRONMENT === 'production'
        ? [
              // 2021
              new Date(Date.UTC(2021, 7, 31)), // aug
              // 2023
              new Date(Date.UTC(2023, 5, 30)), // jun
              new Date(Date.UTC(2023, 6, 31)), // jul
              new Date(Date.UTC(2023, 7, 31)), // aug
              new Date(Date.UTC(2023, 8, 30)), // sept
              new Date(Date.UTC(2023, 9, 31)) // oct
          ].sort(sortDateDescending)
        : [
              // 2021
              new Date(Date.UTC(2021, 7, 31)), // aug
              new Date(Date.UTC(2021, 6, 31)), // jul

              // 2022
              new Date(Date.UTC(2022, 9, 31)), // oct
              new Date(Date.UTC(2022, 10, 30)), // nov
              // 2023
              new Date(Date.UTC(2023, 5, 30)), // jun
              new Date(Date.UTC(2023, 6, 31)), // jul
              new Date(Date.UTC(2023, 7, 31)), // aug
              new Date(Date.UTC(2023, 8, 30)), // sept
              new Date(Date.UTC(2023, 9, 31)) // oct
          ].sort(sortDateDescending);

// MSAT date options
const quarterlyGlobalDateOptions = [
    new Date(Date.UTC(2024, 5, 30)), // end of Q2 (jun)
    new Date(Date.UTC(2024, 8, 30)) // end of Q3 (sep)
].sort(sortDateDescending);

const mairDateOptions = monthlyGlobalDateOptions;

const mairDateToGlobalDateOption = (date: Date): DateOption => {
    const monthEnd = getMonthEnd(date);
    return {
        value: monthEnd.getTime()
    };
};

const msatDateToGlobalDateOption = (date: Date): DateOption => {
    const month = date.getUTCMonth() + 1;
    const quarter = Math.floor(month / 3);
    const monthEnd = getMonthEnd(date);
    const value = monthEnd.getTime();
    const label = `Q${quarter} ${date.getUTCFullYear()}`;
    // disabling Q4 date option
    const disabled = value === 1735689599999;
    return {
        value,
        label,
        disabled
    };
};
/**
 * Date options for global date picker.
 * Will eventually be driven by actual data available.
 */
export const MAIR_GLOBAL_DATE_OPTIONS = mairDateOptions.map(mairDateToGlobalDateOption);
export const MSAT_GLOBAL_DATE_OPTIONS = quarterlyGlobalDateOptions.map(msatDateToGlobalDateOption);

const GLOBAL_DATE_OPTIONS_MAP = {
    [Platforms.MAIR]: MAIR_GLOBAL_DATE_OPTIONS,
    [Platforms.MSAT]: MSAT_GLOBAL_DATE_OPTIONS
};

// Selects newest data by default.
export const MAIR_DEFAULT_GLOBAL_DATE = MAIR_GLOBAL_DATE_OPTIONS[0];
export const MSAT_DEFAULT_GLOBAL_DATE = MSAT_GLOBAL_DATE_OPTIONS[0];

const DEFAULT_GLOBAL_DATE_MAP = {
    [Platforms.MAIR]: MAIR_DEFAULT_GLOBAL_DATE,
    [Platforms.MSAT]: MSAT_DEFAULT_GLOBAL_DATE
};

// Date for Permian target - used for intro drawer demo
export const PERMIAN_DATE = mairDateToGlobalDateOption(new Date(Date.UTC(2021, 7, 31)));

export const getGlobalDateOptions = (platform: Platforms): DateOption[] => GLOBAL_DATE_OPTIONS_MAP[platform];
export const getDefaultGlobalDate = (platform: Platforms): DateOption => DEFAULT_GLOBAL_DATE_MAP[platform];

/** Helper function to retrieve the latest collection date for the specified platform */
export const getLatestCollectionDate = (platform: Platforms) => {
    const dateOptions = getGlobalDateOptions(platform);
    const latest = dateOptions.reduce((prev, curr) => (curr.value > prev.value ? curr : prev));
    return latest;
};

import { Breakpoint, useMediaQuery, useTheme } from '@methanesat/ui-components';

/**
 * Finds the current breakpoint/width of the page.
 *
 * Adapted from MUI docs: https://mui.com/material-ui/react-use-media-query/#migrating-from-withwidth
 *
 * WARNING from MUI docs:
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://legacy.reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export const useCurrentBreakpoint = () => {
    const theme = useTheme();

    // all breakpoints defined in theme
    const breakpoints: Breakpoint[] = theme.breakpoints.keys;

    // find breakpoint matching the current screen size
    const breakpoint = breakpoints.reduce((previous, current) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const screenMatchesCurrent = useMediaQuery(theme.breakpoints.up(current));
        return screenMatchesCurrent ? current : previous;
    }, 'xs');

    return breakpoint;
};

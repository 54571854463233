import GeoJSON from 'geojson';

/**
 * Nicely prints geo coordinates for display in the UI.  Usually we don't need a ton of decimals
 * after latitude/longitude points for sufficient accuracy.
 *
 * Really rough rule of thumb:
 * 0.1 = ~10km
 * 0.01 = ~1km
 * 0.001 = ~100m
 * 0.0001 = ~10m
 * 0.00001 = ~1m
 *
 * See https://en.wikipedia.org/wiki/Decimal_degrees#Precision
 */
export const formatCoordinates = (coordinates: GeoJSON.Position, decimalPrecision = 5) => {
    const [rawLongitude, rawLatitude] = coordinates;

    const longitude = Math.abs(rawLongitude).toFixed(decimalPrecision);
    const latitude = Math.abs(rawLatitude).toFixed(decimalPrecision);
    const longitudeSuffix = rawLongitude >= 0 ? '°E' : '°W';
    const latitudeSuffix = rawLatitude >= 0 ? '°N' : '°S';

    return `${latitude}${latitudeSuffix}, ${longitude}${longitudeSuffix}`;
};

/**
 * Returns the number of significant digits needed to format methane rate values.
 * Also handles negative numbers (e.g. if we want to eventually show negative fluxes).
 */
export const getMaxSignificantDigitsForMethaneRate = (rawValue: number) => {
    /**
     * Determines number length (10 = 2, 100 = 3, 1000 = 4)
     */
    const numLength = Math.round(Math.abs(rawValue)).toString().length;

    return numLength > 1 ? numLength - 1 : 1;
};

/**
 * Via Steve Hamburg and Ritesh: our margin of error is so large here, so that determines the precision we show. Keeping
 * these numbers simple also aids in audience comprehension.  General feedback is to round to significant digits
 * depending on the size of the value:
 * - 12 becomes 10, 15 becomes 20 (round to 1 significant digit)
 * - 711 becomes 710, 715 becomes 720 (round to 2 significant digits)
 * - 1734 becomes 1730, 1737 becomes 1740 (round to 3 significant digits)
 */
export const formatMethaneRate = (rawValue: number, units: string) => {
    const maximumSignificantDigits = getMaxSignificantDigitsForMethaneRate(rawValue);
    const value = rawValue.toLocaleString(undefined, { maximumSignificantDigits });

    return `${value} ${units}`;
};

/**
 * Creates the label that is displayed in the shareable link, located in drawers for map features.
 * Contains the platform, item ID, and location.
 *  @example
 * const label = getItemTitle('MethaneAIR', 'RF06_Level4', 'Permian');
 * // -> 'MethaneAIR RF06 Permian'
 */
export const getFeatureLinkLabel = (platform?: string, itemId?: string, location?: string) => {
    const labelList = [platform, itemId ? itemId.split('_')[0] : null, location].filter((t) => !!t);
    return labelList.join(' ');
};

/** Returns date in format YYYY-MM-DD */
export const formatDateForUrl = (date: Date) => {
    return date.toISOString().split('T')[0];
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapStyles } from '@methanesat/maps';
import { log } from '@methanesat/log';

import { areNotificationsLoading, MapInterface, NotificationMessage } from '../../../../types';
import {
    resetOgiNearPlumes,
    setOgiNearPlumes,
    zoomToPermianAreaEmissions,
    zoomToPermianPlumeEmissions,
    zoomToPermianTarget,
    zoomToPermianTargetBroad,
    zoomToPermianOilAndGas
} from '../emissionsActions';

/**
 * This file defines the slice for managing the state of the map interface. It includes state variables,
 * reducers, and actions related to the map's gesture handling, style, notifications, and various
 * UI elements such as filter chips and emission chips. The slice also handles extra reducers for
 * specific actions related to OGI features and zooming to different areas on the map.
 */

const initialMapInterface: MapInterface = {
    gestureHandling: undefined,
    mapStyle: MapStyles.satelliteMuted,
    notifications: { loading: true },
    ui: {
        isAreaEmissionsChipExpanded: false,
        isOgiOperatorFilterExpanded: false,
        isPlumeEmissionsChipExpanded: false,
        isOilAndGasTypeChipExpanded: false,
        isTargetEmissionsChipExpanded: false
    }
};

export const mapInterfaceSlice = createSlice({
    name: 'mapInterface',
    initialState: initialMapInterface,
    reducers: {
        setGestureHandling: (state, { payload }: PayloadAction<'none' | undefined | string>) => {
            state.gestureHandling = payload;
        },
        setEmissionsMapStyle: (state, { payload }) => {
            if (payload) state.mapStyle = payload;
        },
        addEmissionsMapNotification: (state, { payload }: { payload: NotificationMessage }) => {
            if (areNotificationsLoading(state.notifications)) {
                // the first notification should overwrite `initialState.notifications` with an array of one
                state.notifications = [payload];
            } else {
                // other notifications should be added if they do not duplicate an existing message
                const messages = state.notifications.map((s) => s.message);
                if (payload && !messages.includes(payload.message)) {
                    state.notifications.push(payload);
                }
            }
        },
        removeEmissionsMapNotification: (state, { payload }: { payload: string }) => {
            if (areNotificationsLoading(state.notifications)) {
                log.warn(`Cannot remove notification "${payload}", notifications are loading`);
            } else {
                const message = payload;
                state.notifications = state.notifications.filter((s) => {
                    return message !== s.message;
                });
            }
        },
        updateEmissionsMapNotification: (
            state,
            { payload }: { payload: { message: string; updates: Partial<NotificationMessage> } }
        ) => {
            if (areNotificationsLoading(state.notifications)) {
                log.warn(`Cannot update notification "${payload}", notifications are loading`);
            } else {
                const { message, updates } = payload;
                state.notifications = state.notifications.map((s) => {
                    return {
                        ...s,
                        ...(message === s.message && { ...updates })
                    };
                });
            }
        },
        toggleSingleChip: (state, { payload }: { payload: keyof MapInterface['ui'] }) => {
            const chipState = Object.keys(state.ui) as Array<keyof MapInterface['ui']>;
            chipState.forEach((key) => {
                state.ui[key] = key === payload;
            });
        },
        toggleAreaEmissionChip: (state, { payload }: PayloadAction<boolean>) => {
            state.ui.isAreaEmissionsChipExpanded = payload;
        },
        toggleOgiFilterChip: (state, { payload }: PayloadAction<boolean>) => {
            state.ui.isOgiOperatorFilterExpanded = payload;
        },
        toggleOgiInfrastructureChip: (state, { payload }: PayloadAction<boolean>) => {
            state.ui.isOilAndGasTypeChipExpanded = payload;
        },
        togglePlumeEmissionChip: (state, { payload }: PayloadAction<boolean>) => {
            state.ui.isPlumeEmissionsChipExpanded = payload;
        },
        toggleTargetEmissionChip: (state, { payload }: PayloadAction<boolean>) => {
            state.ui.isTargetEmissionsChipExpanded = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetOgiNearPlumes, (state) => {
                state.gestureHandling = undefined;
            })
            .addCase(setOgiNearPlumes, (state) => {
                state.gestureHandling = 'none';
            })
            .addCase(zoomToPermianAreaEmissions, (state) => {
                state.ui.isTargetEmissionsChipExpanded = false;
                state.ui.isAreaEmissionsChipExpanded = true;
                state.ui.isPlumeEmissionsChipExpanded = false;
                state.ui.isOilAndGasTypeChipExpanded = false;
            })
            .addCase(zoomToPermianPlumeEmissions, (state) => {
                state.ui.isTargetEmissionsChipExpanded = false;
                state.ui.isPlumeEmissionsChipExpanded = true;
                state.ui.isAreaEmissionsChipExpanded = false;
                state.ui.isOilAndGasTypeChipExpanded = false;
            })
            .addCase(zoomToPermianTarget, (state) => {
                state.ui.isTargetEmissionsChipExpanded = true;
                state.ui.isAreaEmissionsChipExpanded = false;
                state.ui.isPlumeEmissionsChipExpanded = false;
                state.ui.isOilAndGasTypeChipExpanded = false;
            })
            .addCase(zoomToPermianTargetBroad, (state) => {
                state.ui.isAreaEmissionsChipExpanded = false;
                state.ui.isOgiOperatorFilterExpanded = false;
                state.ui.isPlumeEmissionsChipExpanded = false;
                state.ui.isOilAndGasTypeChipExpanded = false;
            })
            .addCase(zoomToPermianOilAndGas, (state, action) => {
                const { filterExpanded } = action.payload;

                if (filterExpanded) {
                    state.ui.isOgiOperatorFilterExpanded = true;
                    state.ui.isOilAndGasTypeChipExpanded = false;
                } else {
                    state.ui.isOgiOperatorFilterExpanded = false;
                    state.ui.isOilAndGasTypeChipExpanded = true;
                }

                state.ui.isTargetEmissionsChipExpanded = false;
                state.ui.isPlumeEmissionsChipExpanded = false;
                state.ui.isAreaEmissionsChipExpanded = false;
            });
    }
});

export const {
    setGestureHandling,
    setEmissionsMapStyle,
    addEmissionsMapNotification,
    removeEmissionsMapNotification,
    updateEmissionsMapNotification,
    toggleAreaEmissionChip,
    toggleOgiFilterChip,
    toggleOgiInfrastructureChip,
    togglePlumeEmissionChip,
    toggleTargetEmissionChip,
    toggleSingleChip
} = mapInterfaceSlice.actions;

export default mapInterfaceSlice.reducer;

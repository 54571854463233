import { createSlice } from '@reduxjs/toolkit';

import { MSAT_DEFAULT_GLOBAL_DATE, PERMIAN_DATE } from '../../../../consts';
import { GlobalSettings, Platforms } from '../../../../types';
import { getMonthEnd, getQuarterEnd } from '../../../../utils';
import {
    zoomToPermianAreaEmissions,
    zoomToPermianPlumeEmissions,
    zoomToPermianTarget,
    zoomToPermianTargetBroad
} from '../emissionsActions';

const initialState: GlobalSettings = {
    date: {
        global: MSAT_DEFAULT_GLOBAL_DATE.value
    },
    platform: Platforms.MSAT
};

export const globalSettingsSlice = createSlice({
    name: 'globalSettingsReducer',
    initialState,
    reducers: {
        setGlobalDate: (state, { payload }: { payload: number }) => {
            if (payload !== state.date.global) {
                // if the global date is changing, overwrite the entire date
                // object - this resets all individual target dates
                state.date = { global: payload };
            }
        },
        setPlatform: (state, { payload }) => {
            state.platform = payload;
        },
        setTargetDate: (state, { payload }: { payload: { date: number; targetId: string } }) => {
            const { date, targetId } = payload;
            const platform = state.platform;

            const newDateRangeEnd =
                platform === Platforms.MSAT ? getQuarterEnd(new Date(date)) : getMonthEnd(new Date(date));
            const newDateRangeEndTime = newDateRangeEnd.getTime();

            // Only update the global date if needed. This avoids resetting
            // all target dates if the time period hasn't changed
            // (hooks in components trigger this reset)
            if (newDateRangeEndTime !== state.date.global) {
                state.date = { global: newDateRangeEndTime };
            }
            state.date[targetId] = date;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(zoomToPermianAreaEmissions, (state) => {
                state.date.global = PERMIAN_DATE.value;
            })
            .addCase(zoomToPermianPlumeEmissions, (state) => {
                state.date.global = PERMIAN_DATE.value;
            })
            .addCase(zoomToPermianTarget, (state) => {
                state.date.global = PERMIAN_DATE.value;
            })
            .addCase(zoomToPermianTargetBroad, (state) => {
                state.date.global = PERMIAN_DATE.value;
            });
    }
});

export const { setGlobalDate, setPlatform, setTargetDate } = globalSettingsSlice.actions;
export default globalSettingsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { SelectedFeatureState, SelectedMethaneFeature } from '../../../../types';

/** 
 * This slice contains the state variables for the map feature that has been selected when
 * querying from URL parameters, as well as reducers for setting/resetting these variables.
 *
 * Currently, the app only supports querying for methane features (point sources, area emissions,
 * and targets) from the URL. In the future, if we were to support querying for OGI features from
 * URL parameters, then it would have to be updated with the appropriate state variables and reducers
 * to do so. Link to ticket:
   DP-4301: https://methanesat.atlassian.net/browse/DP-4301?atlOrigin=eyJpIjoiMGRlOTVlNzgzOTgxNDE3MjhlNDYyNTI4Y2EwZDYyMDkiLCJwIjoiaiJ9
*/

const initialState: SelectedFeatureState = {
    selectedMethaneFeature: {
        collectionId: null,
        itemId: '',
        featureType: null,
        coordinates: [],
        targetDate: '',
        targetId: ''
    },
    selectedPlume: null,
    urlParamsInitialized: false,
    fetchingDrawerInfo: false
};

export const selectedFeatureSlice = createSlice({
    name: 'selectedFeature',
    initialState,
    reducers: {
        setSelectedFeatureParams: (state, { payload }: { payload: SelectedMethaneFeature }) => {
            const { featureLat, featureLng } = payload;
            state.selectedMethaneFeature = {
                ...state.selectedMethaneFeature,
                ...payload,
                // Setting featureLat & featureLng explicitly
                // sets them to `undefined` if they are missing from `payload`.
                // This is desirable because we want them to be removed if
                // the new feature does not include them.
                ...{ featureLat, featureLng }
            };
        },
        resetSelectedFeatureParams: (state) => {
            state.selectedMethaneFeature.collectionId = null;
            state.selectedMethaneFeature.itemId = '';
            state.selectedMethaneFeature.featureType = null;
            state.selectedMethaneFeature.coordinates = [];
            state.selectedMethaneFeature.targetDate = '';
            state.selectedMethaneFeature.targetId = '';
            state.selectedMethaneFeature.featureLat = undefined;
            state.selectedMethaneFeature.featureLng = undefined;
        },
        setSelectedPlume: (state, { payload }) => {
            state.selectedPlume = payload;
        },
        setUrlParamsInitialized: (state) => {
            state.urlParamsInitialized = true;
        },
        setFetchingDrawerInfo: (state, { payload }) => {
            state.fetchingDrawerInfo = payload;
        }
    }
});

// reducers
export const {
    setSelectedFeatureParams,
    resetSelectedFeatureParams,
    setSelectedPlume,
    setUrlParamsInitialized,
    setFetchingDrawerInfo
} = selectedFeatureSlice.actions;

export default selectedFeatureSlice.reducer;

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Polygon } from 'geojson';

import { getTitilerPgStacLayer, IconLayer } from '@methanesat/maps';
import { Theme, ThinCrustSVG, useTheme } from '@methanesat/ui-components';

import { maxZoomPlumeTiles, RASTER_COLORMAP_NAMES, SELECTED_AREA_EMISSION_ICON_PATH } from '../../../consts';
import {
    isWithinZoomBounds,
    selectCoordinateFromHighlightedFeature,
    selectEmissionsMapLayerConfigs,
    selectFlooredZoom,
    selectMethaneProduct,
    selectPlatform,
    selectPlumeEmissionRateLayerConfig,
    selectTargetDates,
    setSelectedPlume
} from '../../../reducers';
import { RootState } from '../../../store';
import {
    CaptureFeatureProperties,
    HighlightedObject,
    MethaneLayerIds,
    StacSearchResponse,
    UrlFeatureTypes
} from '../../../types';
import { componentToString, fetchForLayers, getColorRangeColors, getRectangleBBox } from '../../../utils';
import {
    getCollectionName,
    getPointSourceItem,
    getStacItemCOGUrl,
    getCapturesToDisplay,
    CapturePlusCount,
    PointSourceStacResponse
} from '../../data';

type HighlightedObjectWithLayerId = HighlightedObject & {
    layerId: string;
};
type Colormap = string | undefined | RASTER_COLORMAP_NAMES;

/**
 * Gets config from the redux store for area emission rate.
 */
export const useAreaEmissionConfig = () =>
    useSelector((state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.areaEmissionRaster]);

/**
 * Puts a pointer icon on the area emissions layer for selected location
 * @returns point icon at specific location
 */
export const useAreaEmissionIcon = () => {
    const coordinates = useSelector(selectCoordinateFromHighlightedFeature);
    const enabled = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.areaEmissionRaster].enabled
    );

    const ICON_MAPPING = {
        marker: { x: 0, y: 0, width: 58, height: 58 }
    };

    return new IconLayer({
        id: 'selected-area-emissions-icon-layer',
        data: coordinates,
        getIcon: () => 'marker',
        getPosition: () => coordinates as [number, number],
        getPixelOffset: [0, 0], // this coordinate pair moves the point of the icon to align with the user's click
        getSize: 50,
        iconAtlas: SELECTED_AREA_EMISSION_ICON_PATH,
        iconMapping: ICON_MAPPING,
        visible: enabled
    });
};

/**
 * Get historic raster data as specified by given date range.
 * @param colormap
 * @param zoom
 * @returns Array of raster layers
 */
export const useMethaneRasterLayers = (
    colormap: Colormap,
    data: StacSearchResponse<Polygon, CaptureFeatureProperties> | null
) => {
    const targetDates = useSelector(selectTargetDates);
    const platform = useSelector(selectPlatform);
    const product = useSelector(selectMethaneProduct);

    const enabled = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.areaEmissionRaster].enabled
    );
    const flooredZoom = useSelector(selectFlooredZoom);
    const [shouldDraw, setShouldDraw] = useState(false);

    // captures/items from STAC. These are used to get the COG urls
    // for rendering in the raster layers
    const [dataToDisplay, setDataToDisplay] = useState<CapturePlusCount[]>([]);

    const collectionName = useMemo(() => {
        return getCollectionName(product, platform);
    }, [product, platform]);

    // Load raster data only when ready
    useEffect(() => {
        if (data) {
            const capturesToDisplay = getCapturesToDisplay(data, targetDates);
            if (capturesToDisplay) {
                setDataToDisplay(capturesToDisplay);
            }
        }
    }, [data, targetDates]);

    useEffect(() => {
        setShouldDraw(isWithinZoomBounds(flooredZoom, MethaneLayerIds.areaEmissionRaster) && enabled);
    }, [flooredZoom, enabled]);

    /**
     * Returns an array of raster tile layers,
     * one for each selected target-capture id
     */
    return dataToDisplay
        .filter((feature) => feature.collection === collectionName && feature.assets?.COG)
        .map((feature) => {
            const {
                id: itemId,
                properties: { target_id: targetId }
            } = feature;

            const featureBbox = getRectangleBBox(feature.geometry.coordinates[0]);
            const dataUrl = getStacItemCOGUrl(itemId, collectionName, colormap, platform);

            return getTitilerPgStacLayer(
                dataUrl,
                `${MethaneLayerIds.areaEmissionRaster}_${itemId}`,
                flooredZoom,
                {
                    fetch: fetchForLayers
                },
                featureBbox,
                itemId,
                targetId,
                shouldDraw
            );
        });
};

/**
 * Helper function to cache and retrieve the url for the thin crust svg. This is used
 * to avoid issues with immediately calling componentToString from a hook.
 */
const makeThinCrustUrl = (theme: Theme) => {
    let thinCrustUrl: string | undefined;
    return () => {
        if (!thinCrustUrl) {
            thinCrustUrl = `data:image/svg+xml;base64,${btoa(componentToString(<ThinCrustSVG colors={getColorRangeColors(theme, true)} highlighted={false} />))}`;
        }
        return thinCrustUrl;
    };
};

/**
 * Helper function to cache and retrieve the url for the highlighted thin crust svg. This is used
 * to avoid issues with immediately calling componentToString from a hook.
 */
const makeHighlightedThinCrustUrl = (theme: Theme) => {
    let thinCrustUrl: string | undefined;
    return () => {
        if (!thinCrustUrl) {
            thinCrustUrl = `data:image/svg+xml;base64,${btoa(componentToString(<ThinCrustSVG colors={getColorRangeColors(theme, true)} highlighted={true} />))}`;
        }
        return thinCrustUrl;
    };
};

/**
 * Gets config from the redux store for plume flux.
 */
export const usePlumeEmissionRateConfig = () =>
    useSelector((state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.plumeEmissionRate]);

// 112 is a multiple of the current 28 x 28 icon. The
// plume icon isn't expected to get bigger than this.
const SVG_ICON_SIZE = 112;

/**
 * Builds the deck.gl layer for plume emissions rates.
 */
export const usePlumeEmissionRateLayer = (data: StacSearchResponse<Polygon, CaptureFeatureProperties> | null) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const targetDates = useSelector(selectTargetDates);
    const layerProps = useSelector(selectPlumeEmissionRateLayerConfig);

    const [pointSourceFeatureCollections, setPointSourceFeatureCollections] = useState<
        (PointSourceStacResponse | null)[]
    >([]);

    const highlightedFeatureId = useSelector(
        (state: RootState) =>
            selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.plumeEmissionRate].highlightedFeatureId
    );

    const getThinCrustUrl = useMemo(() => makeThinCrustUrl(theme), [theme]);

    const getHighlightedThinCrustUrl = useMemo(() => makeHighlightedThinCrustUrl(theme), [theme]);

    // STAC item id, collection id, and feature type of the visualized feature
    const { collectionId, itemId, featureType, coordinates } = useSelector(
        (state: RootState) => state.pages.emissions.selectedFeature.selectedMethaneFeature
    );

    // Load Point Source data only when ready, or the data changes
    useEffect(() => {
        if (data) {
            const captures = getCapturesToDisplay(data, targetDates);
            if (captures) {
                Promise.all(
                    captures.map((feature) =>
                        getPointSourceItem({
                            stacItemId: feature.id,
                            stacCollectionId: feature.collection,
                            pointSourceUrl: feature?.assets?.GeoJSON?.href,
                            targetId: feature.properties.target_id
                        })
                    )
                ).then((pointSources) => {
                    setPointSourceFeatureCollections(pointSources);
                });
            } else {
                setPointSourceFeatureCollections([]);
            }
        }
    }, [data, targetDates]);

    // Finds the plume feature in `pointSourceFeatureCollections` that matches the selected coordinates
    // when a plume is selected from the URL. If a matching feature is found, it updates
    // `selectedPlume` in the store. This avoids using `openDrawerFromUrl` to
    // prevent the computational overhead of querying STAC again and iterating through
    // all plumes to find the closest match.
    useEffect(() => {
        if (
            pointSourceFeatureCollections.length > 0 &&
            coordinates &&
            collectionId &&
            itemId &&
            featureType === UrlFeatureTypes.Plume
        ) {
            const latitude = coordinates[1];
            const longitude = coordinates[0];

            // Filters for the feature collection that matches the item id, as there may be point sources belong
            // to different captures in one view.
            const matchedSource = pointSourceFeatureCollections.find((source) => source?.id === itemId);

            if (matchedSource) {
                // Now iterate through the features of the matched source to find the correct feature
                const features = matchedSource.features;

                const feature =
                    features &&
                    features.find((f, index) => {
                        const [lng, lat] = (f.geometry as GeoJSON.Point).coordinates;
                        f.index = index;
                        return lng === longitude && lat === latitude;
                    });

                if (feature) {
                    // Attach `itemId`, `collection`, `layerId`, and `id` to the found feature
                    const selectedFeature = {
                        ...feature,
                        // STAC item id
                        itemId: matchedSource.id,
                        // STAC collection id
                        collection: matchedSource.collection,
                        // deck.gl layer id
                        layerId: `${MethaneLayerIds.plumeEmissionRate}_${pointSourceFeatureCollections.indexOf(matchedSource)}`,
                        // Plume id
                        id: feature.properties?.plume_id
                    };
                    dispatch(setSelectedPlume(selectedFeature));
                }
            }
        }
    }, [pointSourceFeatureCollections, collectionId, coordinates, itemId, featureType]);

    // Return the deck.gl layers for plumes
    return pointSourceFeatureCollections?.map((pointSourceData, index) => {
        const plumeIconLayerId = `${MethaneLayerIds.plumeEmissionRate}_${index}`;
        return new IconLayer({
            ...layerProps,
            data: pointSourceData
                ? pointSourceData?.features?.map((feature) => ({
                      ...feature,
                      itemId: pointSourceData.id,
                      collection: pointSourceData.collection,
                      targetId: pointSourceData.targetId
                  }))
                : [],
            fetch: fetchForLayers,
            // Return an object that represents the url for the svg and the max width
            // and max height for the image. It should probably the be same as the resizeWidth
            // and resizeHeight above.
            // https://deck.gl/docs/api-reference/layers/icon-layer#geticon
            getIcon: (d) => {
                if (
                    (layerProps.highlightedObject as HighlightedObjectWithLayerId)?.layerId === plumeIconLayerId &&
                    highlightedFeatureId === d.properties.plume_id
                ) {
                    return {
                        url: getHighlightedThinCrustUrl(),
                        width: SVG_ICON_SIZE,
                        height: SVG_ICON_SIZE
                    };
                }

                return {
                    url: getThinCrustUrl(),
                    width: SVG_ICON_SIZE,
                    height: SVG_ICON_SIZE
                };
            },
            getPosition: (d) => {
                return d.geometry.coordinates;
            },
            highlightColor: [0, 0, 0, 0],
            id: plumeIconLayerId,
            // When loading the icon, it'll resize the image to be of the certain width
            // and height
            // https://loaders.gl/docs/modules/images/api-reference/image-loader#imagebitmap-options
            loadOptions: {
                imagebitmap: {
                    resizeWidth: SVG_ICON_SIZE,
                    resizeHeight: SVG_ICON_SIZE,
                    resizeQuality: 'high'
                }
            },
            maxZoom: maxZoomPlumeTiles,
            pointType: 'icon',
            uniqueIdProperty: 'plume_id',
            updateTriggers: { getIcon: highlightedFeatureId },
            visible: layerProps.visible
        });
    });
};

export * from './analytics';
export * from './attributes';
export * from './browser';
export * from './buildUrl';
export * from './colors';
export * from './component';
export * from './coordinateComputation';
export * from './dashedLine';
export * from './emissions';
export * from './emissionsRasters';
export * from './errorBoundary';
export * from './fetch';
export * from './filterByMethaneRate';
export * from './getSortedUniqueStrings';
export * from './internationalization';
export * from './localStorage';
export * from './numbers';
export * from './pickInfo';
export * from './scales';
export * from './stacSearch';
export * from './stringFormatters';
export * from './time';
export * from './reducerHelpers';
export * from './queryStringHelpers';

/**
 * Normalizes a Next.js route prop to always return a string.
 * For Next.js catch-all routes, only the most relevant part of the array is returned.  For example, a page
 * under `pages/scorecards/[...entityId].tsx` would have an input of ['scorecards', 'fakeoilcompany'], and
 * would output 'fakeoilcompany'.
 * See https://nextjs.org/docs/routing/dynamic-routes
 */
export const normalizeNextRouteProp = (id: string | string[] | undefined): string => {
    return Array.isArray(id) ? id[id.length - 1] : id || '';
};

import { Feature } from 'geojson';
import { isGeoJsonFeature, isPointFeature } from './Geojson';

/**
 * All infrastructure ids.
 * fields, license blocks and production grid
 * are not displayed in the portal.
 */
export enum OGILayerIds {
    basins = 'basins',
    compressorStations = 'compressors',
    equipmentComponents = 'equipment_components',
    // fields = 'fields',
    injectionAndDisposal = 'injection_disposal',
    // licenseBlocks = 'license_blocks',
    lng = 'lng_facilities',
    naturalGasFlaringSites = 'flares',
    offshorePlatforms = 'offshore_platforms',
    petroleumTerminals = 'petroleum_terminals',
    pipelines = 'pipelines',
    pointInfrastructure = 'point_infrastructure',
    processingPlants = 'gathering_processing',
    // productionGrid = 'production_grid',
    refineries = 'refineries',
    stationsOther = 'stations_other',
    tankBatteries = 'tank_batteries',
    tileInfrastructure = 'tile_infrastructure',
    wells = 'wells'
}

export enum OGIPointInfrastructureIds {
    compressorStations = OGILayerIds.compressorStations,
    equipmentComponents = OGILayerIds.equipmentComponents,
    injectionAndDisposal = OGILayerIds.injectionAndDisposal,
    lng = OGILayerIds.lng,
    naturalGasFlaringSites = OGILayerIds.naturalGasFlaringSites,
    offshorePlatforms = OGILayerIds.offshorePlatforms,
    petroleumTerminals = OGILayerIds.petroleumTerminals,
    processingPlants = OGILayerIds.processingPlants,
    refineries = OGILayerIds.refineries,
    stationsOther = OGILayerIds.stationsOther,
    tankBatteries = OGILayerIds.tankBatteries,
    wells = OGILayerIds.wells
}
export const OGILayerIdOrder: OGILayerIds[] = [
    OGILayerIds.pipelines,
    OGILayerIds.wells,
    OGILayerIds.refineries,
    OGILayerIds.processingPlants,
    OGILayerIds.injectionAndDisposal,
    OGILayerIds.compressorStations,
    OGILayerIds.naturalGasFlaringSites,
    OGILayerIds.offshorePlatforms,
    OGILayerIds.petroleumTerminals,
    OGILayerIds.tankBatteries,
    OGILayerIds.stationsOther
] as const;

/**
 * enums and data structures from OGIM DB
 */
type PubPriv = 'public' | 'proprietary';
type SrcType = 'academia' | 'arcgis online' | 'company' | 'data vendor' | 'government' | 'ngo' | 'oginfra.com';
type UpdateFreq = 'annually' | 'daily' | 'irregularly' | 'monthly' | 'other' | 'quarterly' | 'weekly';
type OGIMStatus =
    | 'abandoned'
    | 'completed'
    | 'drilling'
    | 'inactive'
    | 'injecting'
    | 'operational'
    | 'other'
    | 'permitting'
    | 'producing'
    | 'proposed'
    | 'storage, maintenance, or observation'
    | 'under construction';
interface OGISourceData {
    pub_priv: PubPriv;
    src_date: string;
    src_name: string;
    src_id: number;
    src_type: SrcType;
    src_url: string;
    update_freq: UpdateFreq;
}
// types specific to geojson from infrastructure data
interface OGIMGeoJSONProperties {
    ogim_id: number | string;
    layerName: OGILayerIds;
    state_prov: string;
    country: string;
    sources: OGISourceData[];
}

interface Operator {
    operator_id: number;
    operator_name: string;
    ownership_interval: string;
}
export interface InfrastructureGeoJSONProperties extends OGIMGeoJSONProperties {
    fac_id: string | null;
    fac_name: string | null;
    fac_status: string | null;
    fac_type: string | null;
    operators: (Operator | null)[] | null;
    install_date: string | null;
    ogim_status: OGIMStatus | null;
}
// layer-type specific attributes
export interface PipelineFeatureProperties extends InfrastructureGeoJSONProperties {
    details: {
        commodity?: string;
        gas_capacity_mmcfd?: number;
        gas_throughput_mmcfd?: number;
        liq_capacity_bpd?: number;
        liq_throughput_bpd?: number;
        pipe_diameter_mm?: number;
        pipe_length_km?: number;
        pipe_material?: string;
    };
}
export interface PointOGIMGeoJSONProperties extends InfrastructureGeoJSONProperties {
    details: {
        average_flare_temp_k?: number | null;
        commodity?: string | null;
        days_clear_observations?: number | null;
        drill_date?: string | null;
        drill_type?: string | null;
        flare_year?: number | null;
        gas_capacity_mmcfd?: number | null;
        gas_flared_mmcf?: number | null;
        gas_throughput_mmcfd?: number | null;
        liq_capacity_bpd?: number | null;
        liq_throughput_bpd?: number | null;
        num_compr_units?: number | null;
        num_storage_tanks?: number | null;
        segment_type?: string | null;
        site_hp?: number | null;
        spud_date?: string | null;
    };
}

export interface BasinFeatureProperties extends OGIMGeoJSONProperties {
    details: {
        area_km2: number;
        name: string;
        reservoir_type: string;
    };
}

/**
 * END enums & data structures from OGIM DB
 */

/**
 * types related to OGI tile layer
 */

export type OGITileFeature = { id: string | number } & (
    | PointOGITileFeature
    | LineOGITileFeature
    | PolygonOGITileFeature
);

export function isOGITileFeature(data: unknown): data is OGITileFeature {
    if (!isGeoJsonFeature(data)) return false;
    if (!('properties' in data) || !data.properties) return false;
    return 'layerName' in data.properties;
}
export interface OGITileFeatureProperties {
    layerName: OGILayerIds;
}
export interface ClusteredOGITileFeatureProperties extends OGITileFeatureProperties {
    point_count: number;
    clustered: boolean;
}
export type PointOGITileFeature = Required<GeoJSON.Feature<GeoJSON.Point, OGITileFeatureProperties>>;
export type ClusteredPointOGITileFeature = Required<GeoJSON.Feature<GeoJSON.Point, ClusteredOGITileFeatureProperties>>;

/**
 * @returns data is `PointOGITileFeature` | `ClusteredPointOGITileFeature`
 *
 * Feature must have point geometry, correct properties and
 * `properties.layerName` in  `OGIPointInfrastructureIds`.
 */
export function isPointOGITileFeature(
    data: string | Feature
): data is PointOGITileFeature | ClusteredPointOGITileFeature {
    if (!isOGITileFeature(data)) return false;
    if (!isPointFeature(data)) return false;
    const pointLayerNames = Object.values(OGIPointInfrastructureIds);
    // convert to unknown first - this is okay because we are checking for inclusion, not assuming it
    return pointLayerNames.includes(data.properties.layerName as unknown as OGIPointInfrastructureIds);
}
/**
 * @returns `data is ClusteredPointOGITileFeature`
 *
 * Feature must pass checks for `isPointOGITileFeature` and include
 * `clustered` and `point_count` properties. `properties.clustered === true`
 */
export function isClusteredPointOGITileFeature(data: string | Feature): data is ClusteredPointOGITileFeature {
    return (
        isPointOGITileFeature(data) &&
        'clustered' in data.properties &&
        data.properties.clustered &&
        'point_count' in data.properties
    );
}

export type LineOGITileFeature = Required<
    GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString, OGITileFeatureProperties>
>;

export function isLineOGITileFeature(data: string | Feature): data is LineOGITileFeature {
    if (!isOGITileFeature(data)) return false;
    return data.properties.layerName.includes(OGILayerIds.pipelines);
}

export type PolygonOGITileFeature = Required<
    GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, OGITileFeatureProperties>
>;

export function isBasinOGITileFeature(data: string | Feature): data is PolygonOGITileFeature {
    if (!isOGITileFeature(data)) return false;
    return (
        (data.geometry.type === 'Polygon' || data.geometry.type === 'MultiPolygon') &&
        data.properties.layerName.includes(OGILayerIds.basins)
    );
}

export interface RawOGIData {
    ogim_id: number;
    layer_name: OGILayerIds;
}

export type OGISummary = {
    [key in OGILayerIds]?: number;
};

export interface OGINearPoint {
    ogim_id: number;
    layer_name: OGILayerIds;
    distance_m: number;
}

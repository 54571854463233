import { methaneDiffuseRangeFilterValues, methanePlumeRangeFilterValues } from '../consts';
import {
    MethaneDistinctRangeFilterKey,
    MethaneDiffuseRangeFilterKey,
    PlumeProperties,
    PlumeFeature,
    isRasterPointProperties,
    RasterPointFeature
} from '../types';

export function filterByMethaneRate<FluxFeature extends RasterPointFeature | PlumeFeature>(
    data: FluxFeature[],
    selectedFilters: MethaneDiffuseRangeFilterKey[] | MethaneDistinctRangeFilterKey[]
) {
    let filteredData: FluxFeature[] = [];
    selectedFilters.forEach((key: MethaneDiffuseRangeFilterKey | MethaneDistinctRangeFilterKey) => {
        const methaneRangeFilterValue = isRasterPointProperties(data[0].properties)
            ? methaneDiffuseRangeFilterValues[key as MethaneDiffuseRangeFilterKey]
            : methanePlumeRangeFilterValues[key as MethaneDistinctRangeFilterKey];

        let min: number;
        let maxExclusive: number;
        if ('min' in methaneRangeFilterValue) min = methaneRangeFilterValue.min;
        if ('maxExclusive' in methaneRangeFilterValue) maxExclusive = methaneRangeFilterValue.maxExclusive;

        const newData = data.filter((feature: FluxFeature) => {
            const properties = feature.properties as PlumeProperties;
            const methaneValue = properties.flux;
            return isValueInRange(methaneValue, { min, maxExclusive });
        });

        filteredData = [...filteredData, ...newData];
    });
    return filteredData;
}

function isValueInRange(value: number, { min, maxExclusive }: { min: number; maxExclusive: number }) {
    if ((min || min === 0) && (maxExclusive || maxExclusive === 0)) {
        return value >= min && value < maxExclusive;
    }
    // min only
    if (min || min === 0) {
        return value >= min;
    }
    // max only
    if (maxExclusive || maxExclusive === 0) {
        return value < maxExclusive;
    }
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Polygon } from 'geojson';

import { black } from '@methanesat/colors';
import { IconLayer, Layer, PolygonLayer, Position } from '@methanesat/maps';

import { centroid, polygon } from '@turf/turf';

import { DEFAULT_TARGET_FILL_COLOR, ZOOM_THRESHOLDS, getFilledTargetColor } from '../../../consts';
import { selectMethaneTargetLayerConfig, selectPlatform, selectTargetDates } from '../../../reducers';
import { CaptureFeature, CaptureFeatureProperties, StacSearchResponse } from '../../../types';

import { getCapturesToDisplay } from '../../data';

/**
 * Builds the deck.gl layer for the MSat target layer
 */
export const useTargetLayers = (
    zoom: number,
    data: StacSearchResponse<Polygon, CaptureFeatureProperties> | null
): Layer[] => {
    const {
        highlightedFeatureId,
        highlightedObjectIndex: _highlightedObjectIndex,
        ...layerProps
    } = useSelector(selectMethaneTargetLayerConfig);

    const [hasUpdatedLayers, setHasUpdatedLayers] = useState(false);
    const [captures, setCaptures] = useState<CaptureFeature[]>([]);

    // determine time range
    // create new time item "string_from/string_til"
    // modify the search and send
    const platform = useSelector(selectPlatform);
    const targetDates = useSelector(selectTargetDates);

    // Get captures to display when the data is ready or when it changes
    useEffect(() => {
        if (data && data.features) {
            setHasUpdatedLayers(false);

            const captures = getCapturesToDisplay(data, targetDates) || [];
            setCaptures(captures);
            setHasUpdatedLayers(true);
        }
    }, [data, targetDates]);

    const totalMethane = (d: CaptureFeature) => {
        return d.properties.net_total;
    };

    const showFilledTarget = (d: CaptureFeature) => {
        return totalMethane(d);
    };

    const ICON_MAPPING = {
        marker: { x: 0, y: 0, width: 62, height: 63 }
    };

    const multiCapture: CaptureFeature[] = [];
    captures.forEach((capture) => {
        if (capture.properties.monthlyCaptureCount > 1) {
            multiCapture.push(capture);
        }
    });

    const multipleCaptureIcon = new IconLayer({
        data: hasUpdatedLayers ? multiCapture : undefined,
        getIcon: () => 'marker',
        getPosition: (d) => {
            const targetPolygon = polygon(d.geometry.coordinates);
            const targetCentroid = centroid(targetPolygon);
            return targetCentroid.geometry.coordinates as Position;
        },
        getSize: 48,
        iconAtlas: '/img/multi-target-icon.svg',
        iconMapping: ICON_MAPPING,
        id: layerProps.id + 'multiple-capture-icon',
        minZoom: 6,
        pickable: true,
        visible:
            zoom > ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON &&
            zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON
    });

    const getLineWidth = (d: CaptureFeature) => {
        if (d.id === highlightedFeatureId) {
            return 3;
        }
        return 1;
    };

    const targetOutlines = new PolygonLayer<CaptureFeature>({
        ...layerProps,
        data: hasUpdatedLayers ? captures : undefined,
        filled: true,
        getFillColor: (d) => {
            /**
             * Many flights don't yet have a value for total methane, which is needed to color-code captures.
             * For these flights we show an empty box which conveys to the user "this is pending data and will get
             * filled in later".
             */
            return showFilledTarget(d) ? getFilledTargetColor(totalMethane(d), platform) : DEFAULT_TARGET_FILL_COLOR;
        },
        getLineColor: black,
        getLineWidth: getLineWidth,
        getPolygon: (d) => d?.geometry.coordinates,
        lineWidthMaxPixels: 5,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        stroked: true,
        updateTriggers: { getLineWidth: highlightedFeatureId }
    });

    return [targetOutlines, multipleCaptureIcon];
};
